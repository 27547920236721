.tank {
    width: 148px;
    /* Adjust width as needed */
    height: 200px;
    /* Adjust height as needed */
    background-color: transparent;
    /* Tank color */
    position: relative;
    /* border: 2px solid #000; */
    margin-top: -52px;
    margin-left: 10px;
}

.water {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.683);
    /* Water color */
    width: 100%;
    transition: height 0.5s ease;
    /* Smooth transition */
}

input[type='range'] {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    /* Adjust width as needed */
}

.tank_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.tank_image {
    height: 100%;
}

.tank_image>img {
    height: 300px;
    /* width: 100%; */
}


/* .tank_image, */

.tank {
    position: absolute;
}

.water_label {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: #4955A4;
    font-weight: bold;
    transition: top 0.5s ease;
}

.temp_label {
    /* position: absolute; */
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    transition: top 0.5s ease;
}

.label {
    display: block;
    border-bottom: 2px solid #ff0606;
    /* padding-bottom: 0px; */
    margin-top: -10px;
    width: 20px;
    /* Add spacing below the line (optional) */
    top: 0px;
    left: 200%;
    transform: translate(-150%);
}