.door_container {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 3px;
}

.door_section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 8px;
}

.door_icon {
    display: flex;
    align-items: center;
    justify-content: center
}

.timer_section {
    padding: 8px;
}