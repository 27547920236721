.card {
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    -moz-box-shadow: -11px -3px 23px -7px rgba(0, 0, 0, 0.42);
    box-shadow: -11px -3px 23px -7px rgba(0, 0, 0, 0.42);
}

.card_data_section {
    background: #FFFF;
    /* min-height: 250px; */
}

.title_portion {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 18px;
    font-weight: bold;
}

.toggle {
    display: block;
    transform: scale(.7);
    margin-top: -7px;
    margin-right: -20px;
    /* background: red; */
}

.temperature_text {
    font-size: 16px;
    font-weight: bold;
    opacity: .4;
}

.icon>img {
    height: 75px;
    width: 75px;
}

.icon_value_portion {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.value {
    font-size: 40px;
}

.arrow_section {
    background: #FCFCFD;
}

.mode_card {
    background: #FFFF;
    opacity: .7;
    padding: 5px;
    height: 47px;
    width: 47px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: -2px 0px 28px -6px rgba(0, 0, 0, 0.72);
    -moz-box-shadow: -2px 0px 28px -6px rgba(0, 0, 0, 0.72);
    box-shadow: -2px 0px 28px -6px rgba(0, 0, 0, 0.72);
}

.mode_container {
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.mode_text {
    font-weight: bold;
}

.mode_card_active {
    background: #1D8DFD !important;
    color: #FFFF !important;
    opacity: 1 !important;
}

.footer_section {
    display: flex;
    justify-content: space-between;
}

.top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_bar_right_section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.top_bar_switch {
    transform: scale(.7);
}