/* Google Fonts Import Link */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --card-title-font-size: 17px;
    --normal-text-font-size: 13.4px;
    --primary: rgb(44, 47, 62);
    --body-bockground: rgb(44, 47, 62);
    --border-color: rgb(75, 78, 91);
    --sidebar-border-color: rgb(75, 78, 91);
    --sidebar-main-text: rgb(213, 213, 216);
    --sidebar-hover-text-color: #8fbd56;
    --gauge-background: rgb(44, 47, 62);
}

.dark {
    --card-title-font-size: 17px;
    --normal-text-font-size: 13.4px;
    --primary: white;
    --body-bockground: rgb(247, 248, 249);
    --border-color: white;
    --sidebar-border-color: rgb(234, 237, 241);
    --sidebar-main-text: rgb(73, 85, 132);
    /* --gauge-background: #F7F8F9; */
    --gauge-background: #F7F8F9;
}

body {
    background: var(--body-bockground) !important;
}

::-webkit-scrollbar {
    display: none;
}

.zero-padding {
    padding: 0px;
    margin: 0 0 0 0;
}


/* .lite {
    --primary: skyblue;
    --text: black;
} */

.theme-text {
    color: var(--sidebar-main-text) !important;
}

.theme-card-title {
    font-size: var(--card-title-font-size)
}

.theme-normal-text {
    font-size: var(--normal-text-font-size) !important;
}

.theme-background {
    background: var(--primary) !important;
    border: 1px solid var(--border-color);
    /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
}

.theme-gauge-background-color {
    background: var(--gauge-background) !important;
    /* background: transparent !important; */
}


/* for react-suit data table(generator row expand) */

.rs-table-row-expanded {
    background: var(--body-bockground) !important;
}

.rs-table-cell-content {
    background: var(--body-bockground) !important;
}


/* for react-suit data table(generator row expand) */


/* this portion is for room details page gauge inner text color change with theme  */

.CircularProgressbar .CircularProgressbar-text {
    font-size: .7rem;
    text-anchor: middle;
    /* fill: #00040b !important; */
    fill: var(--sidebar-main-text) !important;
}


/* this portion is for room details page gauge inner text color change with theme  */

.theme-table-background {
    background: var(--primary) !important;
    /* border: 1px solid var(--border-color); */
    /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
}


/* .toggle-theme:checked {
    --background-color: black;
    --sidebar-main-text: white;
    --primary: red;
} */

.component-container {
    background: var(--body-bockground) !important;
    padding: 27px;
    height: calc(100vh - 47px);
    /* height: 100%; */
    /* max-height: calc(100vh - 74px); */
    /* height: calc(100%-74px) !important; */
    width: 100%;
    /* background: yellow; */
    overflow: auto;
}

.nav-right-icons {
    margin-right: 25px;
    display: flex;
    align-items: center;
    gap: 20px;
    user-select: none;
}

.home-content i {
    color: var(--sidebar-main-text) !important;
    font-size: 20px;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    /* background: #11101d; */
    background: var(--primary);
    z-index: 100 !important;
    transition: all 0.5s ease;
    color: var(--text) !important;
}

.sidebar-icon {
    /* background: var(--sidebar-main-text); */
    /* color: var(--sidebar-main-text); */
}

.sidebar.close {
    width: 78px;
}

.sidebar {
    border-right: 1px solid var(--sidebar-border-color);
}

.sidebar .logo-details {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}

.sidebar .logo-details i {
    font-size: 30px;
    color: var(--sidebar-main-text);
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
}

.sidebar .logo-details .logo_name {
    font-size: 22px;
    color: var(--sidebar-main-text);
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links {
    height: 100%;
    padding: 30px 0 150px 0;
    overflow: auto;
}

.sidebar.close .nav-links {
    overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar {
    display: none;
}

.sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links li:hover .link_name {
    /* background: #1d1b31; */
    color: var(--sidebar-hover-text-color);
}

.sidebar.close .nav-links li:hover .link_name {
    color: var(--sidebar-main-text);
}

.sidebar.close .nav-links li:hover {
    background: #1d1b31;
}

.sidebar.close .link_name {
    display: none;
}

.sidebar .nav-links li .iocn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link {
    display: block
}

.sidebar .nav-links li i {
    height: 45px;
    min-width: 50px;
    text-align: center;
    line-height: 45px;
    color: var(--sidebar-main-text);
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    /* background: red; */
}

.sidebar.close li i {
    min-width: 78px;
}

.sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
}

.sidebar .nav-links li.secondLayer-showMenu i.second-layer-arrow {
    transform: rotate(-180deg);
}

.sidebar.close .nav-links i.second-layer-arrow {
    display: none;
}

.sidebar.close .second-layer-li {
    display: block;
}

.second-layer-li {
    display: none;
}

.sub-menu-second-layer {
    display: none;
}

.sidebar.close .nav-links i.arrow {
    display: none;
}

.sidebar .nav-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.sidebar .nav-links li a .link_name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--sidebar-main-text);
    transition: all 0.4s ease;
}

.sidebar.close .nav-links li a .link_name {
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 40px;
    margin-top: -10px;
    background: #1d1b31;
    display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
    display: block;
}

.sidebar .nav-links li.secondLayer-showMenu li.second-layer-li {
    display: block;
}

.sidebar .nav-links li.secondLayer-showMenu li.second-layer-li {
    display: block;
}

.sidebar .nav-links li.secondLayer-showMenu .sub-menu-second-layer {
    display: block;
}

.second-layer-title:hover+.sub-menu-second-layer {
    display: block;
}

.sidebar .nav-links li .sub-menu a {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
    display: none;
    /* submenu title*/
}

.sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
}

.sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details {
    background: none;
}

.sidebar.close .profile-details {
    width: 78px;
}

.sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
}

.sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
    padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
    display: none;
}

.sidebar .profile-details .job {
    font-size: 12px;
}

.home-section {
    position: relative;
    background: #E4E9F7;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
}

.sidebar.close~.home-section {
    left: 78px;
    width: calc(100% - 78px);
}

.home-section .home-content {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary);
}

.home-content {
    border-bottom: 1px solid var(--sidebar-border-color)
}


/* .home-content {
    z-index: 2;
} */

.home-section .home-content .bx-menu,
.home-section .home-content .text {
    color: #11101d;
    font-size: 25px;
}

.home-section .home-content .bx-menu {
    margin: 0 15px;
    cursor: pointer;
}

.home-section .home-content .text {
    font-size: 26px;
    font-weight: 600;
}

@media (max-width: 400px) {
    .sidebar.close .nav-links li .sub-menu {
        display: none;
    }
    .sidebar {
        /* z-index: 100; */
        /* width: 78px; */
        /* display: none; */
    }
    .sidebar.close {
        width: 0px;
    }
    /* added to modify mobile sidebar  */
    .sidebar.close .nav-links,
    .sidebar.close .logo-details {
        display: none;
    }
    /* added to modify mobile sidebar  */
    .home-section {
        z-index: 99;
        /* left: 78px;
        width: calc(100% - 78px);
        z-index: 100; */
        left: 0px;
        width: 100%;
        /* z-index: 99; */
        /* z-index: 100; */
    }
    .sidebar.close~.home-section {
        width: 100%;
        left: 0;
    }
    .wizard,
    .tabcontrol {
        display: block;
        width: 100%;
        overflow: hidden
    }
    .wizard a,
    .tabcontrol a {
        outline: 0
    }
    .wizard ul,
    .tabcontrol ul {
        list-style: none !important;
        padding: 0;
        margin: 0
    }
    .wizard ul>li,
    .tabcontrol ul>li {
        display: block;
        padding: 0
    }
    .wizard>.steps .current-info,
    .tabcontrol>.steps .current-info,
    .wizard>.content>.title,
    .tabcontrol>.content>.title {
        position: absolute;
        left: -999em
    }
    .wizard>.steps {
        position: relative;
        display: block;
        width: 100%
    }
    .wizard.vertical>.steps {
        float: left;
        width: 30%
    }
    .wizard.vertical>.steps>ul>li {
        float: none;
        width: 100%
    }
    .wizard.vertical>.content {
        float: left;
        margin: 0 0 0.5em 0;
        width: 70%
    }
    .wizard.vertical>.actions {
        float: right;
        width: 100%
    }
    .wizard.vertical>.actions>ul>li {
        margin: 0 0 0 1em
    }
    .wizard>.steps .number {
        font-size: 1.429em
    }
    .wizard>.steps>ul>li {
        width: 25%;
        float: left
    }
    .wizard>.actions>ul>li {
        float: left
    }
    .wizard>.steps a {
        display: block;
        width: auto;
        margin: 0 0.5em 0.5em;
        padding: 1em 1em;
        text-decoration: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px
    }
    .wizard>.steps a:hover,
    .wizard>.steps a:active {
        display: block;
        width: auto;
        margin: 0 0.5em 0.5em;
        padding: 1em 1em;
        text-decoration: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px
    }
    .wizard>.steps .disabled a {
        background: #eee;
        color: #aaa;
        cursor: default
    }
    .wizard>.steps .disabled a:hover,
    .wizard>.steps .disabled a:active {
        background: #eee;
        color: #aaa;
        cursor: default
    }
    .wizard>.steps .current a {
        background: #2184be;
        color: #fff;
        cursor: default
    }
    .wizard>.steps .current a:hover,
    .wizard>.steps .current a:active {
        background: #2184be;
        color: #fff;
        cursor: default
    }
    .wizard>.steps .done a {
        background: #9dc8e2;
        color: #fff
    }
    .wizard>.steps .done a:hover,
    .wizard>.steps .done a:active {
        background: #9dc8e2;
        color: #fff
    }
    .wizard>.steps .error a {
        background: #ff3111;
        color: #fff
    }
    .wizard>.steps .error a:hover,
    .wizard>.steps .error a:active {
        background: #ff3111;
        color: #fff
    }
    .wizard>.content {
        border: 1px solid #ddd;
        display: block;
        margin: 0.5em;
        min-height: 35em;
        overflow: hidden;
        position: relative;
        width: auto
    }
    .wizard>.actions {
        position: relative;
        display: block;
        text-align: right;
        width: 100%
    }
    .wizard>.actions>ul {
        display: inline-block;
        text-align: right
    }
    .wizard>.actions>ul>li {
        margin: 0 0.5em
    }
    .wizard>.actions a {
        background: #009688;
        color: #fff;
        display: block;
        padding: 0.5em 1em;
        text-decoration: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }
    .wizard>.actions a:hover,
    .wizard>.actions a:active {
        background: #009688;
        color: #fff;
        display: block;
        padding: 0.5em 1em;
        text-decoration: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }
    .wizard>.actions .disabled a {
        background: #eee;
        color: #aaa
    }
    .wizard>.actions .disabled a:hover,
    .wizard>.actions .disabled a:active {
        background: #eee;
        color: #aaa
    }
    .tabcontrol>.steps {
        position: relative;
        display: block;
        width: 100%
    }
    .tabcontrol>.steps>ul {
        position: relative;
        margin: 6px 0 0 0;
        top: 1px;
        z-index: 1
    }
    .tabcontrol>.steps>ul>li {
        float: left;
        margin: 5px 2px 0 0;
        padding: 1px;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px
    }
    .tabcontrol>.steps>ul>li:hover {
        background: #edecec;
        border: 1px solid #bbb;
        padding: 0
    }
    .tabcontrol>.steps>ul>li.current {
        background: #fff;
        border: 1px solid #bbb;
        border-bottom: 0 none;
        padding: 0 0 1px 0;
        margin-top: 0
    }
    .tabcontrol>.steps>ul>li.current>a {
        padding: 15px 30px 10px 30px
    }
    .tabcontrol>.steps>ul>li>a {
        color: #5f5f5f;
        display: inline-block;
        border: 0 none;
        margin: 0;
        padding: 10px 30px;
        text-decoration: none
    }
    .tabcontrol>.steps>ul>li>a:hover {
        text-decoration: none
    }
    .tabcontrol>.content {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 35em;
        overflow: hidden;
        border-top: 1px solid #bbb;
        padding-top: 20px
    }
    .tabcontrol>.content>.body {
        float: left;
        position: absolute;
        width: 95%;
        height: 95%;
        padding: 2.5%
    }
    .tabcontrol>.content>.body ul {
        list-style: disc !important
    }
    .tabcontrol>.content>.body ul>li {
        display: list-item
    }
    .wizard .content {
        min-height: 245px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        overflow-y: auto
    }
    .wizard .content .body {
        padding: 15px
    }
    .wizard .steps a {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        -moz-transition: .5s;
        -o-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s
    }
    .wizard .steps a:active,
    .wizard .steps a:focus,
    .wizard .steps a:hover {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0
    }
    .wizard .steps .done a {
        background-color: rgba(0, 150, 136, 0.6)
    }
    .wizard .steps .done a:hover,
    .wizard .steps .done a:active,
    .wizard .steps .done a:focus {
        background-color: rgba(0, 150, 136, 0.5)
    }
    .wizard .steps .error a {
        background-color: #F44336 !important
    }
    .wizard .steps .current a {
        background-color: #009688
    }
    .wizard .steps .current a:active,
    .wizard .steps .current a:focus,
    .wizard .steps .current a:hover {
        background-color: #009688
    }
}

.user-section {
    display: none;
}

.user-icon:hover .user-section {
    font-style: normal;
    position: absolute;
    display: block;
    /* background-color: (--primary); */
    margin-left: -90px;
}

.user-info {
    background-color: var(--primary);
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    font-size: 17px;
    word-wrap: break-word;
    box-shadow: -1px 5px 18px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 5px 18px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 5px 18px -4px rgba(0, 0, 0, 0.75);
}


/* this postion of style is for Drawer.js file  */

.header-section {
    background: var(--primary);
}


/* this postion of style is for Drawer.js file  */